<template>
  <v-container fluid>
    <Menubar />
    <v-main>
      <v-row justify="center">
        <v-col cols="12" md="8" lg="6">
          <v-scroll-x-reverse-transition hide-on-leave>
            <router-view></router-view>
          </v-scroll-x-reverse-transition>
        </v-col>
      </v-row>
    </v-main>
  </v-container>
</template>

<script>
import Menubar from './nav'
import store from '../../store'

export default {
  name: 'Start',
  components: {
    Menubar,
  },
  mounted() {
    this.startTimer()
  },
  methods: {
    startTimer() {
      store.dispatch('setTimer')
      setTimeout(() => {
        this.startTimer()
      }, 1000)
    },
  },
}
</script>
